footer {
    background-color: var(--text-color);
    padding: 2rem;
}
.footer {
    /*  */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.footer_end {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--hover-color);
    font-weight: bolder;
}
.footer__contact,
.footer__links,
.footer__social,
.footer__subscribe {
    width: 100%;
    flex: 1 1 200px;
    text-align: center;
    /* padding-left: 0.5rem;
    padding-right: 0.5rem; */
}
.footer_title {
    color: var(--hover-color);
    font-weight: bold;
    font-size: 1.3rem;
}

.footer__contact a {
    color: var(--bg-color);
}

.footer h4 {
    font-size: 18px;
    color: var(--bg-color);
}

.footer__links ul,
.footer__social ul {
    list-style: none;
    padding: 0;
}

.footer__links ul li,
.footer__social ul li {
    margin-bottom: 10px;
}

.footer__links a,
.footer__social a {
    text-decoration: none;
    color: var(--text-color);
}

.footer__links a:hover,
.footer__social a:hover {
    color: var(--bg-color);
}

.icon {
    width: 32px; 
    height: 32px;
    
    margin-right: 10px;
}

.icon-link:hover {
    filter: grayscale(50%)
}

@media screen and (max-width: 768px) {
    .footer {
        flex-direction: column;
    }
    .footer__contact,
    .footer__links,
    .footer__social,
    .footer__subscribe {
        width: 100%;
        /* padding-left: 0.5rem;
        padding-right: 0.5rem; */
    }
    
}