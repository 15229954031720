.custom-card {
    /* position: relative; */
    width: 100%;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* text-align: center; */
    align-items: center;
    /* border: 3px red solid; */
}

.custom-card .image-container {
    /* position: relative; */
    width: 70%;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center; */
}

.custom-card .image-container img {
    width: 100%;
    height: auto;
    display: block;
}

.custom-card .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    /* position: absolute; */
    /* top: 50%; */
    /* left: 120%; */
    /* transform: translate(-50%, -50%); */
    width: 100%;
    height: fit-content;
    padding: 1%;
    background-color: var(--text-color);
    color: #fff;
}

.description-list {
    list-style-type: none;
    /* padding: 0; */
    display: flex;
    flex-direction: row;
    /* display: grid;
    grid-template-columns: repeat(4, 1fr); */
    justify-content: center; /* Centrar los elementos */
    text-align: center;
    font-size: 25px;
    gap: 10%;
  }

.custom-card .content h2 {
    font-size: 45px;
    /* margin-bottom: 10px; */
    font-weight: bold;
    text-align: center;
}

.custom-card .content ul {
    list-style: none;
    padding: 0;
}

/* .custom-card .content ul li {
    width: 100%;
} */

/* Media Query para pantallas pequeñas */
@media (max-width: 767px) {
    .custom-card .content h2 {
        font-size: 25px;
        /* margin-bottom: 10px; */
        font-weight: bold;
        text-align: center;
    }
    .description-list{
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: 14px;
    }
    .custom-card .image-container {
        width: 100%;
    }
    
    .custom-card .image-container img {
        width: 100%;
        height: auto;
        display: block;
    }
    /* .custom-card {
        
        width: 100%;
        height: 400px;
        
        display: flex;
        flex-direction: row;
        margin-bottom: 50%;
    }
    .custom-card .image-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
    .custom-card .image-container img {
        width: 100%;
        height: auto;
    }
    
    .custom-card .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        top: 100%;
        left: 50%;
        width: 100%;
        background-color: var(--text-color);
        color: #fff;
        font-size:22px;
    }
    
    .custom-card .content h2 {
        font-size: 30px;
        margin-bottom: 10px;
        font-weight: bold;
    }
    .custom-card .contentZ h2 {
        font-size: 30px;
        margin-bottom: 10px;
        font-weight: bold;
    } */

}