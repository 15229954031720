:root {
  /* --nav-color: #E9EDDE; */
  --nav-color: white;
  --text-color: #1F487E;
   --hover-color: #EC9A29;
   --bg-color: white;
  /* --bg-color: #E9EDDE; */
  /* --bg-color: #616161; */
  --disabled-color: #616161;
}

.nav{
  margin-bottom: 5%;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.section-body {
  color: var(--text-color);
  font-weight: bold;
  background-color: var(--bg-color);
}

.main_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin:0;
  padding:0;
  position: relative;
}

.header {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
}

.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btn-cotiza {
  background-color: var(--text-color);
  color: var(--bg-color);
  font-weight: bold;
  border: none;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.5s ease;
}
.btn-cotiza:hover {
  background-color: var(--hover-color);
}