.whatsapp-button-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .whatsapp-button {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #25D366;
    color: white;
    border-radius: 50px;
    padding: 10px;
    transition: all 0.3s ease;
    text-decoration: none;
    width: 80px;
  }
  .whatsapp-button:hover {
    background-color: var(--hover-color);
    width: 200px;
    /* padding-left: 10px;
    padding-right: 0px; */
  }
  
  .whatsapp-icon {
    width: 60px;
    height: 60px;
    /* margin-right: 8px; */
  }
  
  .whatsapp-text {
    display: none;
    font-size: 16px;
    color: var(--text-color);
  }
  
  
  .whatsapp-button:hover .whatsapp-text {
    display: flex;
  }
  
  /* .whatsapp-button:hover .whatsapp-icon {
    margin-right: 16px;
  } */
  