header {
    
    background-color: var(--nav-color);
    width: 100%;
    display: flex;
    justify-content: center;
    
}

.nav-container {
    width: 80%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    justify-self: center;
    height: fit-content;
    z-index: 10;
    position: fixed;
    background-color: white;
    opacity: 0.8;
    box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.2);
    
}

.nav-container a {
    text-decoration: none;
}


.brand {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 1%;
    padding-left: 5%;
    padding-bottom: 1%;
}

.brand a {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

.brand-img {
    width: 100%;
    margin: 0%;
}

.nav-items {
    width: 60%;
    margin: 0;
    display: flex;
    flex-direction: row;
    list-style: none;
    text-align: center;
    justify-content: left;
    z-index: 12;
}

.nav-item {
    margin: 0%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    padding: 1rem;
    white-space: nowrap;
}

.nav-item.active a {
    color: var(--hover-color);
    font-size: larger;
    font-weight: bold;
    letter-spacing: 0.5px;
    transition: color 0.5s ease;
}

.nav-item a {
    color: var(--text-color);
    font-size: larger;
    /* font-weight: bold; */
    letter-spacing: 0.5px;
    transition: color 0.5s ease;
}

.nav-item a:hover {
    color: var(--hover-color);
}


.nav-item:hover {
    color: var(--hover-color);
}

.dropbtn-content {
    z-index: 20;
    display: none;
    position: absolute;
    top: 70%; 
    left: 0;
    width: 100%;
    font-size: 12px;
    padding: 1rem;
    gap: 0.5rem;
}
  
  .nav-item:hover .dropbtn-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    width: 100%;
    background-color: var(--nav-color);
    z-index: 20;
  }


@media only screen and (max-width: 1208px) {

    .nav-container {
        z-index: 1;
        width: 100vw;
        flex-direction: column;
        align-items: center;
        position: relative;
        opacity: 1;
        position: relative;
    }

    .brand {
        width: 70%;
        justify-content: center;
        text-align: center;
        margin-bottom: 5%;
        padding: 0;
    }

    .nav-items {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        text-align: center;
        margin-bottom: 2%;
    }

    .nav-item:hover .dropbtn-content {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 100%;
        background-color: var(--nav-color);
        opacity: 1.0;
        transition: display 0.5s ease;
    }
    .dropbtn-content {
        display: none;
        top: 70%; 
        left: 0;
        width: 100%;
        font-size: 12px;
        padding: 1rem;
        gap: 0.5rem;
    }

    .nav-item a {
        color: var(--text-color);
        letter-spacing: 0.5px;
        transition: color 0.5s ease;
        width: auto;
    }


}