* {
    font-family: 'Montserrat';

}
/* .home {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    justify-content: center;
    text-align: center;
    background-color: #E9EDDE;
} */

@media only screen and (max-width: 600px) {
    .menu {
        width: 100vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        color: var(--text-color);
        background-color: #E9EDDE;
    }

    /* @media (min-width: 400) {
        .home {
            width: 30%;
            height: 50%;
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    } */

    .carousel-section {
        display: flex;
        width: 100vw;
        border-radius: 1%;
    }

    .carousel-inner {
        width: 100vw;
        height: 100%;
        border-bottom-left-radius: 1%;
        border-bottom-right-radius: 1%;

    }

    .carousel-item {
        width: 100%;
        height: 100%;
    }

    .carousel-item img {
        width: 100%;
        height: 100%;
    }

    .options {
        display: flex;
        flex-direction: column;
        justify-content: left;
        width: 100%;
        height: 50vh;
        /* border-bottom: 3px solid var(--disabled-color); */
        /* box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.2); */

    }

    .links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-self: center;
        align-items: center;
        width: 100%;
        height: 6.5vh;
        border-bottom: 3px solid var(--disabled-color);
        /* box-shadow: 0 6px 6px 6px rgba(0, 0, 0, 0.2); */
    }
    .links-last {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-self: center;
        align-items: center;
        width: 100%;
        height: 6.5vh;
        /* border-bottom: 3px solid var(--disabled-color); */
        /* box-shadow: 0 6px 6px 6px rgba(0, 0, 0, 0.2); */
    }

    .links a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        text-align: left;
        align-self: center;
        align-items: center;
        color: black;
        text-decoration: none;
        font-weight: bolder;
        transition: background-color 0.5s ease;
        padding: 5%;
        font-size: medium;
    }
    .links-last a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        text-align: left;
        align-self: center;
        align-items: center;
        color: black;
        text-decoration: none;
        font-weight: bolder;
        transition: background-color 0.5s ease;
        padding: 5%;
        font-size: medium;
    }

    .links a img {
        width: 10%;
        height: 10%;
        margin-right: 5%;
    }
    .links-last a img {
        width: 10%;
        height: 10%;
        margin-right: 5%;
    }

    .links a:hover {
        background-color: var(--hover-color);
    }
    .links-last a:hover {
        background-color: var(--hover-color);
    }

}



