* {
    font-family: 'Montserrat';
}

.home {
    background-color: white;
    width: 100%;
}

.cover-image {
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%; */
    /* background-image: url('/ruta/a/tu/portada.jpg');
    background-size: cover;
    background-position: center; */
    height: 50%; 
    position: relative;
}

.intro-section {
    background-color: var(--nav-color);
    color: var(--text-color);
    position: absolute;
    top: 85vh; /* La mitad de la portada */
    left: 50%;
    transform: translateX(-50%);
    width: 70%; 
    z-index: 2;
    padding: 4%;
    box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.2);
}
.intro-section p {
    margin: 0 auto;
}

.card-section {
    display: flex;
    flex-direction: column;
/* border: 1px solid black; */
    /* background-color: lightgray; */
    width: 100%;
    margin-left: 0%;
   
}

.services-section {
    height: 100%; 
    padding-top: 10%;
    
}
.services-section h1 {
    text-align: center;
    padding-bottom: 20px;
    font-weight: bold;
    color: var(--hover-color);
    font-size: 55px;
    /* background-color: gray; */
}

@media screen and (max-width: 767px) {
    .services-section h1 {
    text-align: center;
    padding-bottom: 20px;
    font-weight: bold;
    color: var(--hover-color);
    font-size: 35px;
    /* background-color: gray; */
}
    .cover-image {
        height: auto; /* Ajusta la altura de la portada en dispositivos móviles */
        background-size: cover;
    }

    .intro-section {
        position: static;
        top: 0;
        left: 0;
        transform: none;
        width: 100%; /* Ancho completo en dispositivos móviles */
    }
    .intro-section p {
        margin: 20px; /* Espaciado en dispositivos móviles */
    }

    .services-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto; /* Ajusta la altura de la sección de servicios en dispositivos móviles */
        padding-top: 12%; /* Elimina el espacio superior en dispositivos móviles */
    }

    
}
/* #carouselExampleIndicators{
    margin: 0;
    width: 100%;
    height: 650px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-self: center;
} */
/* .portada {
    width: 1340px;
    height: 500px;
    margin: 3%;
    display: flex;
    justify-content: center;
}
.portada img {
    width: 100%;
    height: 100%;
}

.cta-button {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    list-style: none;
    width: 100%;
    margin: 0;
}
.cta {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
}
.btn-act {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    
    text-decoration: none;
    width: 20%;
    height: 100%;
}
.call2act {
    padding-top: 8px;
    margin: 0%;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-self: center;
    color: var(--nav-color);
    font-size: larger;
    font-weight: bold;
    letter-spacing: 0.5px;
    background-color: var(--text-color);
    transition: background-color 0.5s ease;
}
.call2act:hover{
    background-color: var(--hover-color);
} */

@media only screen and (max-width: 600px) {
    .home {
        width: 100vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        color: var(--text-color);
    }

    @media (min-width: 400) {
        .home {
            width: 30%;
            height: 50%;
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .card-section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }
}